import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QuestionOptionData from "./Data/QuestionOptionData.json";
import { FaSpinner } from "react-icons/fa";

const TabButtonStyled = styled.button`
  margin-right: 1px;
  padding: ${(props) =>
    props.$selectedTab === props.$index ? "8px 8px 4px" : "4px 8px"};
  background-color: ${(props) => props.theme.color[props.$rfccode]} !important;
  border: 1px solid ${(props) => props.theme.color[props.$rfccode + "Stroke"]};
  border-radius: 4px 4px 0 0;
  color: black;
`;

const Table = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabDataReady, setTabDataReady] = useState(false);
  const [filteredAndSortedTabData, setFilteredAndSortedTabData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchColumn, setSearchColumn] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch question IDs from the backend
    const fetchQuestionIds = async () => {
      try {
        const response = await fetch("/api/getquestionid/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch question IDs");
        }
        const data = await response.json();
        const questionIds = data.flatMap((item) => item);
        return questionIds;
      } catch (error) {
        console.error("Error fetching question IDs:", error);
        return [];
      }
    };

    const initialize = async () => {
      // Get question IDs dynamically
      const questionIds = await fetchQuestionIds();

      let userResponseBB9 = "Uncertain";

      const storedAnswer = localStorage.getItem("AnswerToQuestion_90");
      if (storedAnswer) {
        const test = JSON.parse(storedAnswer).choice_id;
        const newTest = getAnswer(QuestionOptionData, test);
        if (newTest) {
          userResponseBB9 = newTest.option_choice_name;
        }
      }

      function getAnswer(array, choiceId) {
        return array.find((item) => item.option_choice_id === choiceId);
      }

      function getUserAnswer(array, choiceId) {
        return array.find(
          (item) =>
            item.option_choice_id === choiceId &&
            item.option_choice_item !== null
        );
      }

      function retrieveChoicesFromLocalStorage(values) {
        const choices = [];
        values.forEach((value) => {
          const localStorageItem = localStorage.getItem(
            "AnswerToQuestion_" + value.toString()
          );

          if (localStorageItem) {
            const parsedItem = JSON.parse(localStorageItem);
            if (parsedItem && parsedItem.choice_id) {
              const storedItemResponse = getUserAnswer(
                QuestionOptionData,
                parsedItem.choice_id
              );
              if (storedItemResponse && storedItemResponse.option_choice_name) {
                const storedItemResponseAsText =
                  storedItemResponse.option_choice_name;
                choices.push(storedItemResponseAsText);
              } else {
                choices.push(null);
              }
            } else {
              choices.push(null);
            }
          } else {
            choices.push(null);
          }
        });

        return choices;
      }

      const retrievedChoices = retrieveChoicesFromLocalStorage(questionIds);

      function combineArrays(keys, values) {
        if (keys.length !== values.length) {
          return {};
        } else {
          const combinedArray = {};
          for (let i = 0; i < keys.length; i++) {
            const value = values[i];
            combinedArray[keys[i]] =
              typeof value === "string" ? value.trim() : value;
          }
          return combinedArray;
        }
      }

      const questionsAndAnswers = combineArrays(questionIds, retrievedChoices);

      let topicCategory = global.config.prioritization.KeyTopicCategory;
      let uncertainTopicCategory =
        global.config.prioritization.UncertainTopicCategory;

      const getRiverFunctionPrioritization = (topicCategoryName) => {
        const priorityTopicCategoryText = localStorage.getItem(
          "Prioritization_" + topicCategoryName
        );

        let newColumns = null;

        if (
          priorityTopicCategoryText !== null &&
          priorityTopicCategoryText !== "" &&
          priorityTopicCategoryText !== "null"
        ) {
          try {
            newColumns = JSON.parse(priorityTopicCategoryText);
          } catch (e) {
            console.log("error:", e);
          }
        }

        return newColumns;
      };
      const topicsForThisCategory =
        getRiverFunctionPrioritization(topicCategory);
      const uncertaintopicsForThisCategory = getRiverFunctionPrioritization(
        uncertainTopicCategory
      );
      const retainedTopics =
        topicsForThisCategory === null
          ? []
          : topicsForThisCategory.Retain.items;
      const uncertainRetainedTopics =
        uncertaintopicsForThisCategory === null
          ? []
          : uncertaintopicsForThisCategory.Retain.items;

      function getIndicators(arr1, arr2) {
        const indicators = [];
        arr1.forEach((item) => {
          if (item.river_function_code) {
            indicators.push(item.river_function_code);
          }
        });
        arr2.forEach((item) => {
          if (item.river_function_code) {
            indicators.push(item.river_function_code);
          }
        });

        return indicators;
      }

      const fetchData = async () => {
        setLoading(true);
        const indicators = getIndicators(
          retainedTopics,
          uncertainRetainedTopics
        );
        let states = [""];

        const answerFromLocalStorage = localStorage.getItem(
          "AnswerToQuestion_778"
        );

        if (answerFromLocalStorage) {
          const optionChoices = JSON.parse(
            answerFromLocalStorage
          ).option_choices;

          if (optionChoices && optionChoices.length > 0) {
            states = optionChoices.map((choice) => choice.option_choice_name);
          } else {
            console.error("No option choices found in AnswerToQuestion_778");
          }
        } else {
          console.error("AnswerToQuestion_778 not found in localStorage");
        }
        const body = {
          indicators: indicators,
          answers: questionsAndAnswers,
          response: userResponseBB9,
          states: states,
        };

        try {
          const response = await fetch("/api/getquestiondata/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          });
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          let filteredTabData = [];
          if (data && Array.isArray(data)) {
            filteredTabData = data;
            filteredTabData.forEach((item) => {
              if (item.data.length === 0) {
                item.data = [
                  {
                    question:
                      "Note: Data is only available for BB, CF, WQ, and GM indicators. Other categories of indicators coming soon...",
                  },
                ];
              }
            });

            let filteredAndSortedTabData = indicators.map((label) =>
              filteredTabData.find((item) => item.label === label)
            );
            setFilteredAndSortedTabData(filteredAndSortedTabData);
            setTabDataReady(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      };

      fetchData();
    };

    initialize();
  }, []);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  // START Controls for sorting the table alphabetically by column
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedData = filteredAndSortedTabData[selectedTab]?.data || [];

  const sortedAndFilteredData = sortedData.sort((a, b) => {
    const keyA = a[sortedColumn] || "";
    const keyB = b[sortedColumn] || "";

    if (sortOrder === "asc") {
      return keyA.localeCompare(keyB);
    } else {
      return keyB.localeCompare(keyA);
    }
  });

  const getSortIndicator = (column) => {
    if (column === sortedColumn) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return "↕"; // Default indicators for other columns
  };
  //END Controls for sorting the table alphabetically by column

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const handleDropdownChange = (event) => {
    const column = event.target.value;
    setSearchColumn(column);
  };

  return (
    <div>
      <div className="tab-buttons">
        {tabDataReady &&
          filteredAndSortedTabData.map((tab, index) => {
            const tabLabel = tab?.label;
            if (!tabLabel) {
              console.error(`Tab or tab label is undefined at index ${index}`);
              return null;
            }

            return (
              <TabButtonStyled
                $rfccode={tabLabel.slice(0, 2).toLowerCase()}
                $selectedTab={selectedTab}
                $index={index}
                key={index}
                className={`tab-button ${
                  selectedTab === index ? "active" : ""
                }`}
                onClick={() => handleTabClick(index)}
              >
                {tabLabel}
              </TabButtonStyled>
            );
          })}
      </div>
      <div style={{ marginTop: "10px" }}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <select
          value={searchColumn}
          onChange={handleDropdownChange}
          style={{ marginLeft: "10px" }}
        >
          <option value="all">All</option>
          <option value="question">Question</option>
          <option value="info">Information</option>
        </select>
      </div>
      <div
        className="table-container"
        style={{ overflowX: "auto", maxHeight: "900px" }}
      >
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <FaSpinner className="spinner" style={{ fontSize: "2em" }} />
          </div>
        ) : (
          <table className="table">
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "lightgray",
              }}
            >
              <tr>
                <th>
                  <div
                    onClick={() => handleSort("question")}
                    style={{ cursor: "pointer" }}
                  >
                    Given that the answer to this question is "Yes"{" "}
                    {getSortIndicator("question")}
                  </div>
                </th>
                <th>
                  <div
                    onClick={() => handleSort("info")}
                    style={{ cursor: "pointer" }}
                  >
                    Here is Federal permitting information applicable to your
                    project {getSortIndicator("info")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (sortedAndFilteredData.length === 0) {
                  return (
                    <tr>
                      <td colSpan="4">
                        No data available. Please be sure to answer all
                        questions in the Questionnaire and select key topics in
                        the 'Prioritization' page.
                      </td>
                    </tr>
                  );
                } else {
                  const filteredData = sortedAndFilteredData.filter((data) => {
                    const searchTermLower = searchTerm.toLowerCase();
                    if (searchColumn === "all") {
                      return (
                        (data.question &&
                          data.question
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.info &&
                          data.info.toLowerCase().includes(searchTermLower))
                      );
                    } else {
                      return (
                        data[searchColumn] &&
                        data[searchColumn]
                          .toLowerCase()
                          .includes(searchTermLower)
                      );
                    }
                  });

                  if (filteredData.length === 0) {
                    return (
                      <tr>
                        <td colSpan="4">
                          No data available. Please refine your search terms.
                        </td>
                      </tr>
                    );
                  }

                  return filteredData.map((data, dataIndex) => (
                    <tr
                      key={dataIndex}
                      style={{
                        backgroundColor:
                          dataIndex % 2 === 1 ? "lightgray" : "#fff",
                      }}
                    >
                      <td>{data.question}</td>
                      <td>
                        {data.info ? (
                          <span>
                            {data.info
                              .split(/\b(https?:\/\/\S+)\b/)
                              .map((part, index) => {
                                return index % 2 === 0 ? (
                                  <span key={index}>{part}</span>
                                ) : (
                                  <a
                                    key={index}
                                    href={part}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {part}
                                  </a>
                                );
                              })}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ));
                }
              })()}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Table;
