//import QuestionsListData from "./Data/QuestionsData.json";
import { useEffect } from "react";
import SurveySectionListData from "./Data/SurveySectionData.json";
import { getSurveySectionSummary } from "./Utilities/Util.js";

const QuestionnaireSectionList = ({ questionAnswerListParam }) => {

  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, 500); // 500ms delay for the page to finish loading first
    
  }, []);

  const GoToFirstNotAnsweredQuestion = (qid) => {
    window.location.href = "#qid" + qid;
  };

  return (
    <div className="content-wrapper">
      <div className="card text-dark bg-light">
        <div className="card-header">
          <h5 className="card-title">Questionnaire Sections</h5>
        </div>

        <ul className="list-group">
          {getSurveySectionSummary(
            SurveySectionListData,
            questionAnswerListParam
          ).map((section, i) => (
            <li
              className="list-group-item d-flex justify-content-between align-items-center"
              style={
                section.section_group_txt === "S"
                  ? { backgroundColor: "lightgrey" }
                  : {}
              }
              key={i}
            >
              <a
                className="question-section"
                href={LinkTo(section.section_name)}
                title={section.section_display_txt}
              >
                {section.section_name}
              </a>

              {section.section_group_txt === "" &&
                // all questions are alswered
                (section.answered_percentage === 1 ? (
                  <span
                    className="badge bg-primary rounded-pill"
                    title={
                      "Your have answered all " +
                      section.asked_count +
                      " questions"
                    }
                  >
                    100%
                  </span>
                ) : (
                  <span
                    className="badge bg-danger rounded-pill"
                    title={
                      "Your have answered " +
                      section.answered_count +
                      " questions out of " +
                      section.asked_count +
                      " questions.  Please click here to go to the NotAnswered question."
                    }
                    style={{ cursor: "pointer" }}
                    target-id={getFirstNotAnsweredQuestionId(section)}
                    onClick={() =>
                      GoToFirstNotAnsweredQuestion(
                        getFirstNotAnsweredQuestionId(section)
                      )
                    }
                  >
                    {isNaN(section.answered_percentage)
                      ? ""
                      : parseInt(section.answered_percentage * 100) + "%"}
                  </span>
                ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const LinkTo = (sectionName) => {
  return "#" + sectionName.replace(" ", "_");
};

const getFirstNotAnsweredQuestionId = (section) => {
  return section.not_answered_list !== null &&
    section.not_answered_list.length > 0
    ? section.not_answered_list[0].answer_data.question_id
    : 0;
};

export default QuestionnaireSectionList;
