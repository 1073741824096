import styled from "styled-components";
import QuestionsListData from "../Data/QuestionsData.json";
import QuestionSkipLogicData from "../Data/QuestionSkipLogicData.json";
import SurveySectionListData from "../Data/SurveySectionData.json";

import QuestionnaireSectionList from "../QuestionnaireSectionList.js";
import QuestionSection from "../QuestionSection.js";
import ExportAnswers from "../ExportAnswers.js";
import MainContentsQuestionnairePageOption from "../MainContentsQuestionnairePageOption.js";
import InPageNav from "../InPageNav";
import MainContentsQuestionnaireInstruction from "../MainContentsQuestionnaireInstruction.js";
import navItems from "./navItems";
import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

import { FcInfo } from "react-icons/fc";

import "../../config.js";

import { useState } from "react";

const StyledQuestionnaire = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1320px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    padding: 0 2rem;
  }

  .tooltip-wrapper {
    display: flex;
  }

  .inpage-nav {
    margin-bottom: 1rem;
  }
`;

const ToolTipStyled = styled.span`
  svg {
    width: 24px;
    height: 24px;
  }

  p {
    text-decoration: none;
  }
`;

const MainContentsQuestionnaireV1 = () => {
  const [questionList, setQuestionList] = useState(
    getRevisedQuestionWithAnswers(QuestionsListData)
  );
  const [infoList, setInfoList] = useState([]);

  document.title = global.config.title + ": Questionnaire";
  const onAnswerUpdate = (updatedQuestionAnswerData) => {
    let localInfoList = [];
    const updatedQuestionId = updatedQuestionAnswerData.answer_data.question_id;

    localStorage.setItem(
      "AnswerToQuestion_" + updatedQuestionId,
      JSON.stringify(updatedQuestionAnswerData.answer_data)
    );

    if (
      updatedQuestionAnswerData.input_type_name === "textarea" ||
      updatedQuestionAnswerData.input_type_name === "text"
    ) {
      const infoTxt =
        updatedQuestionAnswerData.section_group_txt +
        updatedQuestionAnswerData.question_display_id +
        ": Saved [" +
        updatedQuestionAnswerData.answer_data.choice_comments +
        "].";
      localInfoList.push(infoTxt);
    } else if (updatedQuestionAnswerData.input_type_name === "radio") {
      const infoTxt =
        updatedQuestionAnswerData.section_group_txt +
        updatedQuestionAnswerData.question_display_id +
        ": Saved.";
      localInfoList.push(infoTxt);

      if (updatedQuestionAnswerData.section_group_txt === "") {
        localStorage.setItem(
          global.config.questionnaire.local_storage_last_answer_timestamp_name,
          new Date().toLocaleString()
        );
      }
    } else if (updatedQuestionAnswerData.input_type_name === "checkbox") {
      const checkedItemList =
        updatedQuestionAnswerData.answer_data.option_choices.filter(
          (item) => item.is_checked
        );

      const infoTxt =
        updatedQuestionAnswerData.section_group_txt +
        updatedQuestionAnswerData.question_display_id +
        ": Saved with " +
        checkedItemList.length +
        " item(s)";
      localInfoList.push(infoTxt);
    }

    let updatedQuestionListWithVisibility = JSON.parse(
      JSON.stringify(questionList)
    );

    // if Skip Logic
    if (
      updatedQuestionAnswerData.input_type_name === "radio" &&
      updatedQuestionAnswerData.has_skip_logic_ind > 0
    ) {
      const skipLogicToShowData = QuestionSkipLogicData.filter(
        (sl) =>
          sl.question_id === updatedQuestionId &&
          sl.answer_id === updatedQuestionAnswerData.answer_data.choice_id &&
          sl.show_hide_ind === "show"
      );

      if (skipLogicToShowData.length === 1) {
        let visibilityToBeChangedQuestions =
          updatedQuestionListWithVisibility.filter(function (q) {
            return this.indexOf(q.question_id) >= 0;
          }, skipLogicToShowData[0].question_list);

        visibilityToBeChangedQuestions.every((questionItem) => {
          questionItem.answer_data.is_visible = 1;

          localStorage.setItem(
            "AnswerToQuestion_" + questionItem.question_id,
            JSON.stringify(questionItem.answer_data)
          );

          return true;
        });
      } else if (skipLogicToShowData.length > 1) {
        // this doesn't have the second condition
        const skipLogicToShowDataWithNoSecondCondition =
          skipLogicToShowData.filter((sl) => sl.question_id2 === 0);

        if (skipLogicToShowDataWithNoSecondCondition.length === 1) {
          let visibilityToBeChangedQuestions =
            updatedQuestionListWithVisibility.filter(function (q) {
              return this.indexOf(q.question_id) >= 0;
            }, skipLogicToShowDataWithNoSecondCondition[0].question_list);

          visibilityToBeChangedQuestions.every((questionItem) => {
            questionItem.answer_data.is_visible = 1;

            localStorage.setItem(
              "AnswerToQuestion_" + questionItem.question_id,
              JSON.stringify(questionItem.answer_data)
            );

            return true;
          });
        }

        // this has the second condition
        const skipLogicToShowDataWithSecondConditionList =
          skipLogicToShowData.filter((sl) => sl.question_id2 > 0);

        skipLogicToShowDataWithSecondConditionList.every((sl) => {
          const secondaryQuestion = updatedQuestionListWithVisibility.filter(
            (q) =>
              q.question_id === sl.question_id2 &&
              q.answer_data.choice_id === sl.answer_id2
          );

          // If secondary question matches with the secondary answer.
          if (secondaryQuestion.length === 1) {
            let visibilityToBeChangedQuestionWithSecondary =
              updatedQuestionListWithVisibility.filter(function (q) {
                return this.indexOf(q.question_id) >= 0;
              }, sl.question_list);

            visibilityToBeChangedQuestionWithSecondary.every((questionItem) => {
              questionItem.answer_data.is_visible = 1;
              localStorage.setItem(
                "AnswerToQuestion_" + questionItem.question_id,
                JSON.stringify(questionItem.answer_data)
              );
              return true;
            });
          } else {
          }

          return true; // required for "EVERY"
        });
      }

      const skipLogicToHideData = QuestionSkipLogicData.filter(
        (sl) =>
          sl.question_id === updatedQuestionId &&
          sl.answer_id === updatedQuestionAnswerData.answer_data.choice_id &&
          sl.show_hide_ind === "hide"
      );

      if (skipLogicToHideData.length === 1) {
        var visibilityToBeChangedQuestions =
          updatedQuestionListWithVisibility.filter(function (q) {
            return this.indexOf(q.question_id) >= 0;
          }, skipLogicToHideData[0].question_list);

        visibilityToBeChangedQuestions.every((questionItem) => {
          questionItem.answer_data.is_visible = 0;

          localStorage.setItem(
            "AnswerToQuestion_" + questionItem.question_id,
            JSON.stringify(questionItem.answer_data)
          );

          return true;
        });
      } else if (skipLogicToHideData.length > 1) {
        // this doesn't have the second condition
        const skipLogicToHideDataWithNoSecondCondition =
          skipLogicToHideData.filter((sl) => sl.question_id2 === 0);

        if (updatedQuestionAnswerData.question_display_id === 43) {
        }

        if (skipLogicToHideDataWithNoSecondCondition.length === 1) {
          let visibilityToBeChangedQuestions =
            updatedQuestionListWithVisibility.filter(function (q) {
              return this.indexOf(q.question_id) >= 0;
            }, skipLogicToHideDataWithNoSecondCondition[0].question_list);

          visibilityToBeChangedQuestions.every((questionItem) => {
            questionItem.answer_data.is_visible = 0;

            localStorage.setItem(
              "AnswerToQuestion_" + questionItem.question_id,
              JSON.stringify(questionItem.answer_data)
            );

            return true;
          });
        }

        // this has the second condition

        /*
                q   a   q2  a2  x       question_list
                310	22	180	21	hide	[[330, 340]]
                310	22	180	22	hide	[[330, 340, 350, 370, 380, 400, 410, 420]]
                */

        const skipLogicToHideDataWithSecondConditionList =
          skipLogicToHideData.filter((sl) => sl.question_id2 > 0);

        skipLogicToHideDataWithSecondConditionList.every((sl) => {
          const secondaryQuestion = updatedQuestionListWithVisibility.filter(
            (q) =>
              q.question_id === sl.question_id2 &&
              q.answer_data.choice_id === sl.answer_id2
          );

          // If secondary question matches with the secondary answer.
          if (secondaryQuestion.length === 1) {
            let visibilityToBeChangedQuestionWithSecondary =
              updatedQuestionListWithVisibility.filter(function (q) {
                return this.indexOf(q.question_id) >= 0;
              }, sl.question_list);

            visibilityToBeChangedQuestionWithSecondary.every((questionItem) => {
              questionItem.answer_data.is_visible = 0;
              localStorage.setItem(
                "AnswerToQuestion_" + questionItem.question_id,
                JSON.stringify(questionItem.answer_data)
              );
              return true;
            });
          } else {
          }

          return true;
        });
      }
    }

    setQuestionList(updatedQuestionListWithVisibility);

    setInfoList(localInfoList.concat(infoList).slice(0, 10));
  };
  return (
    <StyledQuestionnaire>
      <div className='container'>
        <InPageNav navItems={navItems} />
        <div className='row'>
          <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12'>
            <div className='sidebar-links-wrapper sticky-top'>
              <div className='sidebar-links-container'>
                <div>
                  <QuestionnaireSectionList
                    questionAnswerListParam={questionList}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className='col-xl-7 col-lg-8 col-md-8 col-sm-12 col-12'>
            <div className='content'>
              <section className='tooltip-wrapper'>
                <ToolTipStyled className='btn btn-link'>
                  <FcInfo />
                </ToolTipStyled>
                <p>
                  Throughout this questionnaire, you may hover over tool tips
                  for definitions and additional information.
                </p>
              </section>
              {SurveySectionListData.map((sectionData) => (
                <QuestionSection
                  key={sectionData.section_id}
                  sectionData={sectionData}
                  questionListParam={questionList}
                  // answerListParam={answerList}
                  onAnswerUpdate={onAnswerUpdate}
                />
              ))}

              <div>
                <ExportAnswers />
              </div>
            </div>
          </div>

          <div className='col-xl-2 col-lg-1 col-md-2 col-sm-12 col-12'>
            <MainContentsQuestionnaireInstruction />
            <MainContentsQuestionnairePageOption />
          </div>
        </div>
      </div>
      <SequentialPageNav prev='/Impacts/Instructions' next='/Impacts/Results' />
    </StyledQuestionnaire>
  );
};

// this function is to Combine Answers from LocalStorage to Existing Questions.
const getRevisedQuestionWithAnswers = (QuestionsListData) => {
  const newQuestionList = [];
  for (let qData of QuestionsListData) {
    const jsonAnswer = localStorage.getItem(
      "AnswerToQuestion_" + qData.question_id
    );

    const newQuestion = qData;

    try {
      if (jsonAnswer) {
        const answerItem = JSON.parse(jsonAnswer);

        if (
          answerItem.is_visible === null ||
          answerItem.is_visible === undefined
        )
          answerItem.is_visible = 999; // 999 = Default to Show

        newQuestion.answer_data = answerItem;
      } else {
        newQuestion.answer_data = {
          question_id: qData.question_id,
          is_visible: 999, // 999 = Default to Show
          choice_id: null,
          choice_comments: null,
          option_choices: null,
          answer_datetime: null,
        };
      }
    } catch (e) {}

    newQuestionList.push(newQuestion);
  }

  return newQuestionList;
};

export default MainContentsQuestionnaireV1;
